import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'src/store/store';
import { showModal } from 'src/modules/shared/modal/actions';
import { dismissFeaturedPost } from 'src/modules/shared/context/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import i18n from 'src/utils/translate';
import { LinkButton } from 'styles-js/buttons';
import { ResultsPostTitle, ResultsPost } from 'styles-js/resultsList';
import { ModalType } from 'src/constants/modalTypes';
export default function DismissFeedback({
  featuredPostId
}: {
  featuredPostId: number;
}) {
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(dismissFeaturedPost());
  };
  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    trackFootfallEvent(Event.Clicked, {
      clickType: 'campaign-Feedback',
      clickMetadata: e.target.value
    });
    if (e.target.value === 'other') {
      dispatch(showModal({
        modalType: ModalType.Feedback,
        modalProps: {
          campaignId: featuredPostId,
          title: i18n.t('Send us feedback about this campaign'),
          onClose
        }
      }));
    } else {
      onClose();
    }
  };
  return <ResultsPost data-sentry-element="ResultsPost" data-sentry-component="DismissFeedback" data-sentry-source-file="dismissFeedback.tsx">
      <PreTitle data-sentry-element="PreTitle" data-sentry-source-file="dismissFeedback.tsx">
        {i18n.t('Improve your experience')}
        <LinkButton onClick={onClose} data-sentry-element="LinkButton" data-sentry-source-file="dismissFeedback.tsx">{i18n.t('Hide')}</LinkButton>
      </PreTitle>
      <Title data-sentry-element="Title" data-sentry-source-file="dismissFeedback.tsx">{i18n.t('Why are you hiding this?')}</Title>
      <Label data-sentry-element="Label" data-sentry-source-file="dismissFeedback.tsx">
        <input type="radio" value="irrelevant" onChange={onSelect} />
        {i18n.t(`It's irrelevant`)}
      </Label>
      <Label data-sentry-element="Label" data-sentry-source-file="dismissFeedback.tsx">
        <input type="radio" value="too-often" onChange={onSelect} />
        {i18n.t('I see it too often')}
      </Label>
      <Label data-sentry-element="Label" data-sentry-source-file="dismissFeedback.tsx">
        <input type="radio" value="other" onChange={onSelect} />
        {i18n.t('Other (please explain)')}
      </Label>
    </ResultsPost>;
}
const PreTitle = styled.div`
  display: flex;
  margin-bottom: 10px;
  button {
    margin-left: auto;
  }
`;
const Title = styled(ResultsPostTitle)`
  &:hover, &:focus, &:active {
    color: ${({
  theme
}) => theme.colorBlack};
  }
`;
const Label = styled.label`
  display: flex;
  input {
    margin-right: 10px;
  }
`;