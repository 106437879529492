import { SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import { Featured } from 'src/constants/types';
import { formatLink } from 'src/utils/featuredPosts';
import i18n from 'src/utils/translate';
import { CtaWrapper, DisabledButton, CampaignLink, CampaignImg, TitleContainer, Title, HideButton, Body } from 'src/modules/community/shared/components/featuredPosts/normalFeaturedPost';
import { SecondaryButton } from 'styles-js/buttons';
type Props = {
  disabled?: {
    callToActionUrl?: boolean;
    hideButton?: boolean;
    image?: boolean;
    snippet?: boolean;
    title?: boolean;
  } | Record<string, never>;
  featuredPost: Featured;
  onClick: (e: SyntheticEvent) => void;
  onDismiss: () => void;
};
export default function StickyFeaturedPost({
  disabled,
  featuredPost,
  onDismiss,
  onClick
}: Props) {
  const formattedLink = formatLink(featuredPost.callToActionUrl);
  const handleClick = (e: SyntheticEvent) => {
    onClick(e);
  };
  return <Container data-sentry-element="Container" data-sentry-component="StickyFeaturedPost" data-sentry-source-file="stickyFeaturedPost.tsx">
      <ContainerInner $hasTwoCol={!featuredPost.imageUrl && disabled?.image} data-sentry-element="ContainerInner" data-sentry-source-file="stickyFeaturedPost.tsx">
        {featuredPost.imageUrl && <CampaignLink href={formattedLink} rel="nofollow ugc noreferrer" target="_blank" onClick={onClick}>
            <CampaignImg $backgroundImage={featuredPost.imageUrl} />
          </CampaignLink>}

        <div>
          <TitleContainer data-sentry-element="TitleContainer" data-sentry-source-file="stickyFeaturedPost.tsx">
            <Title $isDisabled={disabled?.title} data-sentry-element="Title" data-sentry-source-file="stickyFeaturedPost.tsx">
              {disabled?.title ? featuredPost.title : <a href={formattedLink} rel="nofollow ugc noreferrer" target="_blank" onClick={e => handleClick(e)}>
                    {featuredPost.title}
                  </a>}
            </Title>
            <HideButton $isDisabled={disabled?.hideButton} type="button" onClick={onDismiss} data-sentry-element="HideButton" data-sentry-source-file="stickyFeaturedPost.tsx">{i18n.t('Hide')}</HideButton>
          </TitleContainer>
          <Body $isDisabled={disabled?.snippet} data-sentry-element="Body" data-sentry-source-file="stickyFeaturedPost.tsx">{featuredPost.snippet}</Body>
        </div>

        {featuredPost.callToActionText && <CtaWrapper>
            {disabled?.callToActionUrl ? <DisabledButton disabled={true} text={featuredPost.callToActionText} /> : <SecondaryButton as="a" href={formattedLink} rel="nofollow ugc noreferrer" target="_blank" onClick={e => handleClick(e)}>
                  {featuredPost.callToActionText}
                </SecondaryButton>}
          </CtaWrapper>}
      </ContainerInner>
    </Container>;
}
export const Container = styled.div`
  background-color: ${props => props.theme.colorWhite};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: ${props => props.theme.shadowTop};
  z-index: 1050;
  border-top: 5px solid ${props => props.theme.colorGreen};
`;
export const ContainerInner = styled.div<{
  $hasTwoCol?: boolean;
}>`
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 20px;
  @media (${props => props.theme.underScreenSmall}) {
    padding: 15px;
  }
  @media (${props => props.theme.overScreenSmall}) {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 1.5fr minmax(20%, auto);
    align-items: center;
    gap: 20px;
    padding: 26px 20px;
    ${TitleContainer} {
      position: static;
    }
    ${Title} {
      padding-right: 0;
    }
    ${HideButton} {
      top: 10px;
      right: 20px;
    }
  }
  ${CampaignLink}, ${Body} {
    margin-bottom: 0;
    @media (${props => props.theme.underScreenSmall}) {
      display: none;
    }
  }
  ${({
  $hasTwoCol
}) => $hasTwoCol && css`
    @media (${props => props.theme.overScreenSmall}) {
      grid-template-columns: minmax(0, 1.5fr) minmax(20%, auto);
    }
  `}
`;